<template>
  <v-card style="margin:8px;" height="100%">
    <v-avatar size="256"><v-img :src="imageUrl"></v-img></v-avatar>
    <v-card-title algin="center" class="justify-center"><b>{{mitglied.name}}</b></v-card-title>
    <b style="color: rgb(208,208,208); font-size: 18px;">{{mitglied.instrumente}}</b>
    <v-card-text>{{mitglied.beschreibung}}</v-card-text>
  </v-card>
</template>

<script>
import Server from "../server.js";

export default {
  name: "JBandmitglied",
  props: {
    mitglied: Object
  },
  computed: {
    imageUrl(){
      return Server.baseURL + '/assets/' + this.mitglied.bild;
    }
  }
}
</script>

<style scoped>
.v-card>.v-card__progress+:not(.v-btn):not(.v-chip), .v-card>:first-child:not(.v-btn):not(.v-chip) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
</style>