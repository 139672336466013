<template>
  <div>
    <j-titlebar></j-titlebar>
    <v-container>
      <v-card :loading="impressum === undefined">
        <v-card-title class="justify-center">Impressum</v-card-title>
        <v-card-text class="text-left">
          <v-row>
            <v-col cols="12">
              <div>
                <b>Inhaber</b>
                <p>{{impressum.inhaber}}</p>
                <p style="white-space: pre-line">{{impressum.adresse}}</p>
                <p style="white-space: pre-line">{{impressum.kontakt}}</p>
              </div>
            </v-col>
            <v-col cols="12">
              <div>
                <b>Webmaster</b>
                <p>{{impressum.webmaster}}</p>
              </div>
            </v-col>
            <v-col cols="12">
              <span v-html="impressum.text"></span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import JTitlebar from "../components/JTitlebar.vue";
import Server from "../server.js";
export default {
  name: "Impressum",
  components: {JTitlebar},
  data: () => ({
    impressum: undefined,
  }),
  mounted() {
    this.getData();
  },
  methods: {
    getData(){
      Server.getImpressum().then(response => {
        this.impressum = response.data;
      })
    }
  }
}
</script>

<style scoped>

</style>