
export default class Server {

    static baseURL = "https://manage.folksandfriends.de";

    static getImpressum(){
        return new Promise(((resolve, reject) => {
            fetch(this.baseURL + "/items/Impressum/1").then(response => {
                resolve(response.json());
            }).catch((err) => {
                reject(err);
            })
        }))

    }

    static getWelcomeText(){
        return new Promise(((resolve, reject) => {
            fetch(this.baseURL + "/items/willkommenstext/1").then(response => {
                resolve(response.json());
            }).catch((err) => {
                reject(err);
            })
        }))
    }

    static getGruppenBild(){
        return new Promise(((resolve, reject) => {
            fetch(this.baseURL + "/items/Gruppenbild/1").then(response => {
                resolve(response.json());
            }).catch((err) => {
                reject(err);
            })
        }))
    }

    static getBandmitglieder(){
        return new Promise(((resolve, reject) => {
            fetch(this.baseURL + "/items/Bandmitglieder").then(response => {
                resolve(response.json());
            }).catch((err) => {
                reject(err);
            })
        }))
    }

    static getAnkündigungen(){
        return new Promise(((resolve, reject) => {
            fetch(this.baseURL + "/items/ankuendigungen").then(response => {
                resolve(response.json());
            }).catch((err) => {
                reject(err);
            })
        }))
    }

    static getImages(){
        return new Promise(((resolve, reject) => {
            fetch(this.baseURL + "/items/bilder").then(response => {
                resolve(response.json());
            }).catch((err) => {
                reject(err);
            })
        }))
    }

    static createContactRequest(email, text){
        return new Promise((resolve, reject) => {
            let data = {
                email: email,
                text: text
            };
            fetch(this.baseURL + "/items/Kontaktanfragen", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: JSON.stringify(data)
            }).then(() => {
                resolve();
            }).catch((err) => {
                reject(err);
            })
        })
    }
    static getImageFiles(ids){
        return new Promise(((resolve, reject) => {
            let filter = {
                id: {
                    _in: ids
                }
            };
            fetch(this.baseURL + "/items/bilder_files?filter=" + JSON.stringify(filter)).then(response => {
                resolve(response.json());
            }).catch((err) => {
                reject(err);
            })
        }))
    }
    static getVideos(){
        return new Promise(((resolve, reject) => {
            fetch(this.baseURL + "/items/videos").then(response => {
                resolve(response.json());
            }).catch((err) => {
                reject(err);
            })
        }))
    }

}