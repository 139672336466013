<template>
  <v-footer
      dark
      padless
  >
    <v-card
        flat
        tile
        style="width: 100%;"
        class="text-center"
    >
      <v-card-text class="justify-lg-space-around">
        <v-row>
          <v-col lg="6" sm="6" md="6" cols="12">
              <b>Adresse</b>
              <p style="white-space: pre-line" >{{address}}</p>
          </v-col>
          <v-col lg="6" sm="6" md="6" cols="12">
            <b>Kontakt</b>
            <p style="white-space: pre-line">{{contact}}</p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <a style="text-decoration: none !important; color: white;" href="https://manage.folksandfriends.de">Login</a> | {{ new Date().getFullYear() }} — <strong>Musikschule KSK</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>

import Server from "../server.js";

export default {
  name: "JFooter",
  data: () => ({
    address: "",
    contact: "",
  }),
  mounted() {
    this.getData();
  },
  methods: {
    getData(){
      Server.getImpressum().then((response) => {
        this.address = response.data.adresse;
        this.contact = response.data.kontakt;
      })
    }
  }
}
</script>

<style scoped>

</style>