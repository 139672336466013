<template>
  <div>
    <j-titlebar></j-titlebar>
    <v-container class="my-4">
      <v-card>
        <v-row class="diagonal-gradient">
          <v-col cols="12" md="12" class="transparent">
            <v-card align="center" class="transparent">
              <v-card-title class="justify-center"><h2>Mitglieder</h2></v-card-title>
              <v-card-text>
                <v-container>
                  <v-row class="justify-center">
                    <v-col cols="12" md="4" v-for="mitglied in mitglieder" :key="mitglied.id">
                    <j-bandmitglied :mitglied="mitglied"></j-bandmitglied>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Server from "../server.js";
import JBandmitglied from "../components/JBandmitglied.vue";
import JTitlebar from "../components/JTitlebar.vue";

export default {
  name: "Bandmitglieder",
  components: {JTitlebar, JBandmitglied},
  mounted() {
    this.getData();
  },
  data: () => ({
    mitglieder: [],
  }),
  methods: {
    getData(){
      Server.getBandmitglieder().then((response) => {
        this.mitglieder = response.data;
      })
    }
  }
}
</script>

<style scoped>

</style>