<template>
  <div>
    <j-titlebar></j-titlebar>
    <v-container class="my-4">
      <v-card>
        <v-row class="diagonal-gradient">
          <v-col cols="12" md="12" class="transparent">
            <v-card align="center" class="transparent">
              <v-card-title class="justify-center"><h2>Bilder</h2></v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col v-for="image in images" cols="3" :key="'image' + image.id">
                      <j-galerie-img-component :image-object="image"></j-galerie-img-component>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-title class="justify-center"><h2>Videos</h2></v-card-title>
              <v-card-text>
                <v-container>
                  <template v-for="video in videos">
                    <div v-if="video.typ === 'HEADLINE'" :key="'video'+video.id" class="text-start">
                      <b>{{video.ueberschrift}}</b>
                      <v-divider></v-divider>
                    </div>
                    <div v-else :key="'video'+video.id" class="pa-4">
                      <iframe width="560" height="315" :src="loadURL(video.video_link)" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                  </template>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import JTitlebar from "../components/JTitlebar.vue";
import Server from "../server.js";
import JGalerieImgComponent from "../components/JGalerieImgComponent.vue";
export default {
  name: "Galerie",
  components: {JGalerieImgComponent, JTitlebar},
  data: () => ({
    images: [],
    videos: [],
  }),
  mounted() {
    this.getData();
  },
  methods: {
    loadURL(youtubeURL) {
      const youtubeEmbedTemplate = "https://www.youtube.com/embed/";
      const url = youtubeURL.split(
          /(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/
      );
      const YId =
          undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];
      const topOfQueue = youtubeEmbedTemplate.concat(YId);
      return topOfQueue;
    },
    getData(){
      Server.getImages().then((response => {
        this.images = response.data;
      }))
      Server.getVideos().then((response) => {
        this.videos = response.data;
      })
    }
  }
}
</script>

<style scoped>

</style>