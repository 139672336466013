<template>
  <div>
    <j-titlebar></j-titlebar>
    <v-container class="my-4">
      <v-card>
        <v-row class="diagonal-gradient">
          <v-col cols="12" md="12" class="transparent">
            <v-card align="center" class="transparent">
              <v-card-title class="justify-center"><h2>Aktuelles</h2></v-card-title>
              <v-card-text>
                <v-container>
                  <template  v-if="infos.length > 0">
                    <v-card outlined v-for="(info, index) in infos" :key="'ankündigung' + index" class="mb-6">
                      <v-card-title>
                        {{ info.Titel }}
                        <v-spacer></v-spacer>
                        <div class="text-subtitle-1">
                          {{new Date(info.date_created).toLocaleDateString()}}, {{new Date(info.date_created).toLocaleTimeString()}}
                        </div>
                      </v-card-title>
                      <v-divider class="mx-4"></v-divider>
                      <v-card-text v-html="info.Text" class="text-start"></v-card-text>
                    </v-card>
                  </template>
                  <template v-else>
                    Aktuell gibt es noch keine Einträge.
                  </template>


                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import JTitlebar from "../components/JTitlebar.vue";
import Server from "../server.js";
export default {
  name: "Ankündigungen",
  components: {JTitlebar},
  data: () => ({
    infos: []
  }),
  mounted() {
    this.loadData();
  },
  methods: {
    loadData(){
      Server.getAnkündigungen().then((response) => {
        this.infos = response.data.reverse();
      })
    }
  }
}
</script>

<style scoped>

</style>