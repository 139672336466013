<template>
        <div>
            <v-toolbar dark>
                <v-toolbar-title @click="go2Main" ><img src="../assets/FaFICON.png" style="width: 140px; cursor: pointer"/></v-toolbar-title>
                <v-spacer></v-spacer>

                <v-toolbar-items class="hidden-sm-and-down">
                    <v-btn text @click="go2Main">Home</v-btn>
                    <v-btn text @click="go2Ankuendigungen">Aktuelles</v-btn>
                    <v-btn text @click="go2Galerie">Galerie</v-btn>
                    <v-btn text @click="go2Bandmitglieder">Bandmitglieder</v-btn>
                </v-toolbar-items>

                <v-menu class="hidden-md-and-up">
                    <template v-slot:activator="{ on }">
                        <v-app-bar-nav-icon class="hidden-md-and-up" v-on="on"></v-app-bar-nav-icon>
                    </template>
                    <v-list>
                        <v-list-item @click="go2Main">
                            <v-list-item-title>
                                Home
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="go2Bilder">
                            <v-list-item-title>
                                Bilder
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="go2Videos">
                            <v-list-item-title>
                                Videos
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="go2Bandmitglieder">
                            <v-list-item-title>
                                Bandmitglieder
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-toolbar>
        </div>
</template>

<script>
    export default {
        name: "JTitlebar",
        methods: {
            go2Main(){
                this.$router.push('/');
            },
            go2Ankuendigungen(){
                this.$router.push('/ankuendigungen');
            },
            go2Galerie(){
                this.$router.push('/galerie');
            },
            go2Bandmitglieder(){
                this.$router.push('/bandmitglieder');
            },
        }
    }
</script>

<style scoped>

</style>
