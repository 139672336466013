<template>

  <div>
    <j-titlebar></j-titlebar>
    <v-container class="my-4">
      <v-card>
        <v-row class="diagonal-gradient">
          <v-col cols="12" md="12" class="transparent">
            <v-card align="center" class="transparent">
              <v-card-title class="justify-center"><h2>Kontaktformular</h2></v-card-title>
              <v-alert v-model="error">Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut!</v-alert>
              <v-card-text v-if="!sended">
                <v-container>
                  <v-text-field label="Email" outlined v-model="email"></v-text-field>
                  <v-textarea label="Text" outlined v-model="text"></v-textarea>
                </v-container>
              </v-card-text>
              <v-card-text v-else class="justify-center">
                Anfrage wurde verschickt!
              </v-card-text>
              <v-card-actions v-if="!sended">
                <v-spacer></v-spacer>
                <v-btn dark rounded @click="sendContactRequest">Send</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import JTitlebar from "../components/JTitlebar.vue";
import Server from "../server.js";
export default {
  name: "Kontakt",
  components: {JTitlebar},
  data: () => ({
    sended: false,
    error: false,
    email: '',
    text: '',
  }),
  methods: {
    sendContactRequest(){
      Server.createContactRequest(this.email, this.text).then(() => {
        this.sended = true;
      }).catch((err) => {
        console.log(err);
        this.error = true;
      })

    }
  }
}
</script>

<style scoped>

</style>