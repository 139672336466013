<template>

  <div class="text-center">
    <v-dialog
        v-model="dialog"
        width="70vw"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-card v-bind="attrs"
                v-on="on">
          <v-img
              max-height="20vh"
              :src="imageUrls[0]"
              class="grey darken-4"
          ></v-img>
          <v-card-subtitle>
            {{ imageObject.titel }} ({{imageUuids.length}})
          </v-card-subtitle>
        </v-card>
      </template>

      <v-carousel height="70vh" style="background: darkgray;">
        <v-carousel-item
            v-for="(item,i) in imageUrls"
            :key="i"
            reverse-transition="fade-transition"
            transition="fade-transition"
        >
          <v-img :src="item" height="70vh" contain  :alt="imageObject.titel"/>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
  </div>





</template>

<script>
import Server from "../server.js";

export default {
  name: "JGalerieImgComponent",
  props: {
    imageObject: Object
  },
  data: () => ({
    imageUuids: [],
    dialog: false,
  }),
  mounted() {
    this.getImageUUids();
  },
  methods: {
    getImageUUids(){
        Server.getImageFiles(this.imageObject.bilder).then((response) => {
          this.imageUuids = response.data;
        })
    }
  },
  computed: {
    imageUrls(){
      return this.imageUuids.map((ob) => {
        return Server.baseURL + '/assets/' + ob.directus_files_id;
      })
    }
  }
}
</script>

<style scoped>

</style>