<template>
  <v-container class="my-12">
    <v-row>
      <v-col cols="12" md="4" class="column">
        <tile transparent :image-url="require('../assets/SchriftFaF.png')" center></tile>
      </v-col>
      <v-col cols="12" md="6" class="column">
        <tile :image-url="groupImageUrl"></tile>
      </v-col>
      <v-col cols="12" md="2" class="column py-0">
        <v-row class="px-md-2" dense>
          <v-col cols="12" class="half-column">
            <tile :image-url="require('../assets/noten.jpg')" :text="'Musikschule'" :go-to="'https://musikschule-ksk.de/'" :font-size="fontSize"></tile>
          </v-col>
          <v-col cols="12" class="half-column">
            <tile :image-url="require('../assets/formular.jpg')" :go-to="'/kontaktformular'" :text="'Kontaktformular'" :font-size="fontSizeKontakt"></tile>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" class="column">
        <tile :image-url="require('../assets/bilder.jpg')" :text="'Aktuelles'" :go-to="'/ankuendigungen'"></tile>
      </v-col>
      <v-col cols="12" md="8" class="column">
        <tile transparent :text="text" text-box :font-size="fontSizeText"></tile>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" class="column">
        <tile :image-url="require('../assets/videos.jpg')" :text="'Galerie'" :go-to="'/galerie'"></tile>
      </v-col>
      <v-col cols="12" md="4" class="column">
        <tile :image-url="require('../assets/trommel.jpg')" :text="'Bandmitglieder'" :go-to="'/bandmitglieder'"></tile>
      </v-col>
      <v-col cols="12" md="4" class="column">
        <tile :image-url="require('../assets/harfe.jpg')" :text="'Impressum'" :go-to="'/impressum'"></tile>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Tile from "../components/Tile.vue";
import Server from "../server.js";

export default {
  name: 'Home',
  components: {Tile},
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    fontSize(){
      switch(this.$vuetify.breakpoint.name){
        case 'xs': return 40
        case 'sm': return 40
        case 'md': return 20
        case 'lg': return 28
        case 'xl': return 40
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    fontSizeKontakt(){
      switch(this.$vuetify.breakpoint.name){
        case 'xs': return 40
        case 'sm': return 40
        case 'md': return 15
        case 'lg': return 20
        case 'xl': return 32
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    fontSizeText(){
      switch(this.$vuetify.breakpoint.name){
        case 'xs': return 15
        case 'sm': return 15
        case 'md': return 20
        case 'lg': return 20
        case 'xl': return 32
      }
    },
    groupImageUrl(){
      return Server.baseURL + '/assets/' + this.groupImageId;
    }
  },
  data: () => ({
    text: '',
    groupImageId: '',
  }),
  mounted() {
    this.getData();
  },
  methods: {
    getData(){
      Server.getWelcomeText().then(response => {
        this.text = response.data.text;
      });
      Server.getGruppenBild().then((response) => {
        this.groupImageId = response.data.Bild;
      })
    }
  }
}
</script>
<style scoped>
  .column {
    height: 33vh;
  }
  .half-column{
    height: 16.5vh;
  }

  .col-12{
    padding: 4px !important;
  }
</style>
