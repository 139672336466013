<template>
  <v-hover v-slot:default="{ hover }">
  <v-card @click.stop="go" :disabled="goTo == undefined" :elevation="hover ? 12 : 0" class="d-flex align-center transparent tileStyle" v-bind:class="center ? 'center' : 'cover'" v-bind:style="{backgroundImage: 'url(' + imageUrl+ ')', cursor: goTo !== undefined ? '' : 'default'}" style="overflow: hidden;" dark>

    <v-card-text>
      <div class="display-1 flex-grow-1 text-center">
        <p v-bind:style="{ color: textBox ? 'antiquewhite' : 'white'}" v-bind:class="textBox ? 'text' : ''">
          {{text}}
        </p>
      </div>
    </v-card-text>

  </v-card>
  </v-hover>
</template>

<script>
export default {
  name: "Tile",
  props: {
    transparent: {
      type: Boolean,
      default: false,
    },
    goTo: {
      type: String,
      default: undefined,
    },
    fontSize: Number,
    imageUrl: String,
    textBox: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ""
    },
    center: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    go(){
      if(this.goTo !== undefined){
        if(this.goTo.startsWith("http")){
          window.location = this.goTo;
        }else{
          this.$router.push(this.goTo);
        }
      }
    }
  }
}
</script>

<style scoped>

.tileStyle{
  height: 100%;
}
.text{
  overflow: hidden;
  text-overflow: ellipsis;
  color: antiquewhite;
  font-size: 1.5vw;
  line-height: 2vw;
}
.cover {
  background-size: cover;
  background-position: right top;
}
.center {
  background-size: contain;
  background-position: center center;
}

</style>