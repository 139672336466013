<template>
  <v-app>
    <v-main class="background">
      <router-view/>
    </v-main>
    <j-footer></j-footer>
  </v-app>
</template>

<script>

import JFooter from "./components/JFooter.vue";
export default {
  name: 'App',
  components: {JFooter},
  data: () => ({
    //
  }),
};
</script>
<style>
.background{
  background-image: url("./assets/background.jpg");
  background-position: left top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: rgba(255,255,255, 1);
}
.transparent {
  background-color: white!important;
  opacity: 1 !important;
  border-color: transparent!important;
  webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.v-card__text {
  opacity: 1 !important;
}

.diagonal-gradient {
  background: linear-gradient(to bottom right, #DCC591, pink);
}
</style>
