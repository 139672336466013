import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Impressum from "../views/Impressum.vue";
import Bandmitglieder from "../views/Bandmitglieder.vue";
import Galerie from "../views/Galerie.vue";
import Kontakt from "../views/Kontakt.vue";
import Ankündigungen from "../views/Ankündigungen.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: '/bandmitglieder',
    name: 'Bandmitglieder',
    component: Bandmitglieder
  },
  {
    path: '/galerie',
    name: 'Galerie',
    component: Galerie
  },
  {
    path: '/ankuendigungen',
    name: 'Ankündigungen',
    component: Ankündigungen
  },
  {
    path: '/kontaktformular',
    name: 'Contact',
    component: Kontakt
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
